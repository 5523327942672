// Buttons
// --------------------------------------------------------------------------------------------

.sunset-border {
	background-color: transparent;
	border: 2px solid red;
	border-color: #ff6638 #f04b67 #ee4594;
	
	// line-height: 1.3;
}

%sunset-border-text {
	@extend .sunset-border;
	// padding: 6px 40px;
	line-height: 1.6;
	color: #fb6488;
}

.btn {
	margin-top: 3px;
	margin-bottom: 3px;
	text-transform: uppercase;
	letter-spacing: 0.5em;
	font-size: inherit;
	cursor: pointer;
	transition: background-color 0.25s ease-out, color 0.25s ease-out, padding .5s, font-size .5s, border-color .25s, transform .08s, border-radius .08s;
	font-weight: normal;
	// font-weight: bold;
	padding: 12px 40px;
	-webkit-font-smoothing: antialiased;

	@include media-breakpoint-down(md){
		padding: 10px 20px;
		letter-spacing: 0.3em;
		font-size: 15px;
		white-space: normal;
	}

	&:active {
		transform: scale(0.97);
	}
}



.btn-sunset {
	@extend %sunset-border-text;

	// Add additional background for header so the grid isn't so distracting
	.section-header & {
		background-color: rgba(#1d0538, 0.5);
	}

	
	&.btn-sunset--with-bg {
		background-color: rgba(#09050c, 0.3);
	}

	&:hover,
	&:focus {
		// background-color: rgba(238, 69, 109, 0.25);
		background-color: lighten(rgba(#1d0538, 0.5), 10%); // rgba(165, 69, 238, 0.5);
		// color: white;
		// border-color: white;
		color: hover-color-shift(#fb6488);
		border-color: hover-color-shift(#ff6638) hover-color-shift(#f04b67) hover-color-shift(#ee4594);
		
		outline: 0;
		box-shadow: none; // get rid of bootstrap blue glow
		// border-radius: 4px;

		> span {
			.backgroundcliptext & {
				color: white;
				background: none;
				-webkit-background-clip: initial;
				-webkit-text-fill-color: white;
			}
		}
	}

	

	&:active {
		transform: scale(0.97);
		border-radius: 4px;
	}

	&.hero {
		&:hover,
		&:focus {
			transform: skew(-0.1rad);
		}

		&:active { 
			transform: scale(0.97) skew(-0.08rad);
		}
	}

	> span {
		@extend .text-sunset;
	}
}

.btn-sunset--large {
	font-size: 18px;
	padding: 13px 30px;

	@include media-breakpoint-up(sm) {
		font-size: 24px;
		// border-width: 3px;
		padding: 16px 60px;
	}
}

.btn-green {
	// background: none;
	// background: #258300;
	background: rgba(1, 111, 1, 0.26);
	color: white;
	border-color: #258300;
	border-width: 2px;
	border-top-color: #4cac26;
	line-height: 1.6;
	// padding: 6px 40px;

	span {
		font-weight: normal;
		-webkit-font-smoothing: antialiased;
	}

	&:hover,
	&:focus {
		background: rgba(#2ea200, 0.25);
	}
}

.btn-flush {
	margin-bottom: 0;
}

.input-group-btn > .btn {
	// @extend .sunset-border;
	// height: 2.5rem;
	// line-height: 1;
	// padding: 12px;
	margin: 0;
	// border-left: none;

	// &:hover {
	// 	background-color: rgba(238, 69, 109, 0.25);
	// }
}

.btn-arrow {
	width: 55px;
	padding-left: 10px;
	padding-right: 10px;
	background: rgba(black, 0.001) url(../img/input-arrow.png) no-repeat center;

	&:active {
		background-image: url(../img/input-arrow.png); // Bootstrap clears out background image on active for some reason
	}
}

.trailer-button {
	margin-top: auto;
	margin-bottom: auto;
}