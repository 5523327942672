.form-control-sunset {
	@extend %sunset-border-text;

	text-transform: uppercase;
	letter-spacing: 0.5em;
	font-size: inherit;
	transition: background-color 0.25s ease-out, color 0.25s ease-out, padding .5s, font-size .5s, border-color .25s, transform .15s;
	font-weight: 300;
	background-color: rgba(#1d0538, 0.5);

	@include media-breakpoint-down(sm){
		letter-spacing: 0.3em;
		font-size: 16px; // must be at least 16px to avoid iOS zooming in when focused
	}

	&:hover,
	&:focus {
		background-color: lighten(rgba(#1d0538, 0.5), 10%);
		border-color: hover-color-shift(#ff6638) hover-color-shift(#f04b67) hover-color-shift(#ee4594);
	}

	&:focus {
		// @extend %sunset-border-text;
		background-color: lighten(rgba(#1d0538, 0.5), 10%);
		// background-color: rgba(238, 69, 109, 0.25);
		// border-color: white;
		border-width: 2px;
		box-shadow: none;
		color: white;
		text-align: left !important;
		

		
		// color: hover-color-shift(#fb6488);
		
	}

	&:valid {
		text-align: left !important;
	}

	&::placeholder {
		color: #ee456d;
		transition: color .3s;
	}

	&:focus::placeholder {
		transition: none;
		color: rgba(#ee456d, 0.3) !important;
		color: transparent !important;
	}
}

.form-group {
	width: 700px;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}