.flex-vertical {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.flex-fill {
	flex-grow: 1;
}

.cursor-zoom-in {
	cursor: zoom-in;
}

.cursor-none {
	pointer-events: none;
}

.text-select-none {
	user-select: none;
}