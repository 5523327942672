// Social icons

.scl-icon {
	display: inline-block;
	margin-left: 1.5%;
	margin-right: 1.5%;
	transition: transform .1s;

	img {
		display: block;
		// transition: filter .3s;
	}

	&:hover {
		opacity: 0.8;
	}

	&:hover img {
		// filter: grayscale(100%) brightness(300%); // white
		filter: hue-rotate(-20deg) brightness(150%) saturate(120%); // purple
	}

	&:active {
		transform: scale(0.95);
	}

	@include media-breakpoint-down(xs){
		width: 70px;
	}
}